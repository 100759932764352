import uploader from "@/plugins/azure-file-upload";

const IMAGE_TYPE = "IMAGE";
const DOCUMENT_TYPE = "DOCUMENT";
const THUMBNAILS = [IMAGE_TYPE, DOCUMENT_TYPE];

const DOCUMENT_TYPES = ["application/pdf"];
const IMAGE_TYPES = ["image/jpeg", "image/bmp", "image/png"];

const getFileType = (type) => {
  if (DOCUMENT_TYPES.includes(type)) return DOCUMENT_TYPE;
  if (IMAGE_TYPES.includes(type)) return IMAGE_TYPE;
  return null;
};

const getFileMeta = (meta) => {
  const { tenantData, userData, extension, filePath, file } = meta;
  const { aName, cName } = tenantData;
  const uri = `https://${aName}.blob.core.windows.net`;
  let path = "";
  let thumbnailPath = "";
  let blobName = "";
  let thumbnailName = "";
  if (filePath) {
    blobName = `${filePath}/${file.webkitRelativePath}`;
    path = `https://${aName}.blob.core.windows.net/${cName}/${blobName}`;
  } else {
    const filePrefix = `${new Date().getTime()}${tenantData.id}${
      userData.id
    }_wtrpublic`;
    blobName = `${filePrefix}.${extension}`;
    thumbnailName = `${filePrefix}_thumbnail.png`;
    thumbnailPath = `https://${aName}.blob.core.windows.net/${cName}/${thumbnailName}`;
    path = `https://${aName}.blob.core.windows.net/${cName}/${blobName}`;
  }

  return {
    uri,
    blobName,
    thumbnailName,
    path,
    thumbnailPath,
    cName,
  };
};

const getToken = (response) => {
  const hasPayload =
    response.data.payload && response.data.payload.length
      ? response.data.payload[0]
      : {};
  const { sasToken, resourceId } = hasPayload;
  return {
    sasToken,
    resourceId,
  };
};

const getFileMetaThumbnail = (meta) => {
  const { tenantData, userData, extension, filePath, file, originalFileExt } =
    meta;

  let { aName, cName } = tenantData;
  if (
    ["jpg", "jpeg", "png", "pdf", "jfif"].includes(
      originalFileExt.toLowerCase()
    )
  ) {
    cName = "files";
  } else {
    cName = "processedfiles";
  }
  const uri = `https://${aName}.blob.core.windows.net`;
  let path = "";
  let thumbnailPath = "";
  let blobName = "";
  let thumbnailName = "";
  if (filePath) {
    blobName = `${filePath}/${file.webkitRelativePath}`;
    path = `https://${aName}.blob.core.windows.net/${cName}/${blobName}`;
  } else {
    const filePrefix = `${new Date().getTime()}${tenantData.id}${userData.id}`;
    blobName = `${filePrefix}.${extension}`;
    thumbnailName = `${filePrefix}_thumbnail.png`;
    thumbnailPath = `https://${aName}.blob.core.windows.net/${cName}/${thumbnailName}`;
    path = `https://${aName}.blob.core.windows.net/${cName}/${blobName}`;
  }

  return {
    uri,
    blobName,
    thumbnailName,
    path,
    thumbnailPath,
    cName,
  };
};

export default {
  methods: {
    $uploadLogoFact(data) {
      const userData = this.$session.getUserData();
      const tenantData = this.$session.getTenantData();
      return this.$fileUploader({
        ...data,
        tenantData,
        userData,
      });
    },
    $fileUploader(fileMeta) {
      const { file, onFileProgress, onFileCancel } = fileMeta;
      const id = fileMeta.id || 1;
      return new Promise((resolve, reject) => {
        const extension = this.$getFileExtension(file.name);
        if (extension === "exe") {
          reject(new Error("Executable files are not allowed to upload"));
          return;
        }
        const originalFileExt = this.$getFileExtension(
          fileMeta.originalFileName
        );

        const { uri, blobName, path, thumbnailPath, cName } =
          fileMeta.isThumbnailUpdate
            ? getFileMetaThumbnail({
                ...fileMeta,
                extension,
                originalFileExt,
              })
            : getFileMeta({
                ...fileMeta,
                extension,
              });
        this.$api
          .getSasTokenWriteFact(cName)
          .then((response) => getToken(response))
          .then(({ sasToken, resourceId }) => {
            if (!sasToken) {
              reject(new Error("Sas Token is missing in the parameter"));
              return;
            }
            const blockSize = uploader.getBlockSize(file.size);
            const blobService = uploader.createBlobService({
              blobUri: uri,
              sasToken,
              onFileCancel,
              blockSize,
              cName,
              blobName,
            });
            uploader.start(blobService, {
              cName,
              blobName,
              file,
              blockSize,
              onError: (error) => {
                reject(error);
              },
              onSuccess: () => {
                const fileType = getFileType(file.type);
                if (
                  THUMBNAILS.includes(fileType) &&
                  !fileMeta.isThumbnailUpdate
                ) {
                  this.$api.uploadThumbnailFact({
                    sasToken: `?${sasToken}`,
                    blobUrl: path,
                    type: fileType,
                  });
                }
                resolve({
                  data: {
                    status: true,
                    payload: {
                      thumbnailPath,
                      uri: path,
                      resourceId,
                      containerName: cName,
                      blobName,
                    },
                  },
                });
              },
              onProgress: (metaData) => {
                if (!onFileProgress) return;
                onFileProgress(id, metaData);
              },
            });
          });
      });
    },
  },
};
