import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import SubscriptionMixin from "./subscription-mixin";
import uploadMixin from "./upload-mixin";
import permissions from "../helpers/permissions";
import { handleResponse, handleError } from "../api";
import {
  SLIDE_IMAGE,
  SLIDE_DIGITALSLIDE,
  SLIDE_RADIOLOGY,
  SLIDE_VIDEO,
  SLIDE_DOCUMENT,
} from "../constants/constants";

export default {
  computed: {
    ...mapGetters({
      $userData: "getUserData",
      $homeData: "getHomeData",
      $subscriptionData: "getSubscriptionData",
      $userRole: "getUserRole",
      $sessionToken: "getAuthToken",
      $tenantData: "getTenantData",
      $siteData: "getSiteData",
      $tenantConfigData: "getTenantConfigData",
      $modalData: "getModalData",
      $userAgent: "getUserAgent",
      $loader: "getLoaderStatus",
      $dashboardHeight: "getDashboardHeight",
      $dashboardWidth: "getDashboardWidth",
    }),
    $pathParams() {
      const route = this.$route.params;
      const params = _.mapValues(route, (value) =>
        value != null ? this.$decryptData(value) : value
      );
      return params;
    },
    $hasUpdatedBatch() {
      const flag = this.$tenantData && this.$tenantData.batchVersion === 2;
      return flag;
    },
    $queryParams() {
      return this.$route.query;
    },
    $routeName() {
      return this.$route.name;
    },
    $isPublicTenant() {
      return this.$tenantData.public;
    },
    $getTissueDomain() {
      return this.$tenantConfigData && this.$tenantConfigData.domain;
    },
    $tenantLogo() {
      if (!this.$tenantData) return this.$icons.NO_THUMBNAIL_FOUND;
      if (this.$tenantData) return this.$icons.WTR_LOGO;
      //if (this.$tenantData.logo) return this.$tenantData.logo;
      return this.$icons.NO_THUMBNAIL_FOUND;
    },
    $tenantLoaded() {
      return !!(this.$tenantData && this.$tenantData.id);
    },
  },
  mixins: [uploadMixin, SubscriptionMixin],
  methods: {
    ...mapActions([
      "$setHeaderAction",
      "$loaderAction",
      "$setModalAction",
      "sessionDataAction",
    ]),
    $getSharableUrl(endPoint, disableEncrypt) {
      let link = `${window.location.origin}${endPoint || ""}`;
      if (disableEncrypt) return link;
      try {
        link = window.btoa(link);
      } catch (err) {
        console.log(err); // eslint-disable-line
      }
      return link;
    },
    $logout(expiry, cantChangeRoute, evt, cb, customMessage) {
      let message = "Thank you for using our application";
      if (customMessage) {
        message = customMessage;
      } else if (expiry) {
        message = "Session Expired";
      } else if (this.$tenantData && this.$tenantData.organization) {
        message = `Thank you for using World Tumor Registry`;
      }
      if (cantChangeRoute) {
        this.$sessionLogout(message, cb);
      } else {
        this.$session.setMeta({
          message,
          hasEvent: evt,
          cb,
          cantChangeRoute,
        });
        this.$changeRoute({ name: "logout" });
      }
    },
    $sessionLogout(message, cb) {
      const session = this.$session.logout();
      this.$store.dispatch("sessionDataAction", session);
      this.$toastr("success", message);
      if (cb) cb();
    },
    $redirectTo(redirectRoute) {
      if (redirectRoute && !this.$session.openRoutes.includes(redirectRoute)) {
        return redirectRoute;
      }

      if (!this.$isProfileCompleted(this.$userData)) {
        this.$changeRoute({
          name: "profile",
          params: { id: this.$userData.id },
        });
      } else {
        return {
          name: this.$canAccess("administration", "canCreateTenants")
            ? "tenants"
            : "my-slide-box",
        };
      }
    },
    $encryptData(data) {
      return this.$session.encryptData(data);
    },
    $decryptData(data) {
      return this.$session.decryptData(data);
    },
    $getResource(src, resourceId, containerName, altImage) {
      return this.$session.getResource(
        src,
        resourceId,
        containerName,
        altImage
      );
    },
    $changeRouteToTab(route) {
      const params = _.mapValues(route.params, (value) =>
        this.$encryptData(value != null ? value : "")
      );
      const payload = {
        ...route,
        params,
      };

      const routeData = this.$router.resolve(payload);
      window.open(routeData.href, "_blank");
    },
    $changeRoute(route) {
      const params = _.mapValues(route.params, (value) =>
        this.$encryptData(value != null ? value : "")
      );
      const payload = _.assign(route, {
        params,
      });
      this.$router.push(payload);
    },
    $canAccess(currentModule, currentAction, role, disableTokenCheck) {
      if (!this.$tenantLoaded) return false;
      const tokenData = this.$session.getTokenData();
      const tokenRole =
        tokenData && tokenData.roles ? tokenData.roles[0] : null;
      const userRole = role || this.$session.getUserRole();
      if (!userRole) return false;
      if (tokenRole !== userRole && !disableTokenCheck) {
        this.$logout(false, false, "Corrupted Token");
        return false;
      }
      return permissions.canAccess({
        currentModule,
        currentAction,
        userRole,
      });
    },
    $handleError(error) {
      return handleError(error, {
        onUnauthorize: (message) => {
          this.$logout();
          this.$modalAlert("info", message);
        },
        onBadRequest: (message) => {
          this.$modalAlert("error", message);
        },
        onError: (message) => {
          this.$modalAlert("error", message);
        },
      });
    },
    $handleResponse(response) {
      return handleResponse(response, (message) => {
        this.$modalAlert("error", message);
      });
    },
    $setVueEditor() {
      const userAgent = this.$session.getUserAgent();
      if (userAgent && userAgent.browser === "ie") {
        window.$(".ql-picker-label").click((e) => {
          window
            .$(e.target)
            .parents(".ql-picker.ql-color-picker")
            .addClass("ql-expanded");
        });
      }
    },
    $getPopOverTriggers() {
      const triggers = "focus";
      const userAgent = this.$session.getUserAgent();
      if (!userAgent) {
        return triggers;
      }
      if (userAgent.os === "ios") {
        return `hover ${triggers}`;
      }
      return triggers;
    },
    $getCardBodyStyle(height) {
      if (this.$session.getUserAgent().isMobile) return null;
      const maxHeight = `${height}px`;
      return {
        "min-height": maxHeight,
        "max-height": maxHeight,
      };
    },
    $getWatermark(data, tenant, tenantLogo) {
      let logo;
      const maxWidth = `${5}%`;
      if (data && data.watermarkLogo) {
        logo = this.$getResource(
          data.watermarkLogo,
          data.resourceId,
          data.containerName
        );
      } else if (tenant?.resourceId && tenant?.containerName) {
        logo = this.$getResource(
          tenantLogo,
          tenant.resourceId,
          tenant.containerName
        );
      } else {
        logo = tenantLogo;
      }
      return {
        logo,
        opacity: data && data.watermarkOpacity ? data.watermarkOpacity : 0.2,
        width: maxWidth,
      };
    },
    $downloadImage(src, cb, watermark, margin) {
      const options = {
        margin: margin,
        path: watermark.logo,
        opacity: watermark.opacity ? watermark.opacity : 0.5,
        gravity: watermark.gravity ? watermark.gravity : "n",
        done: cb,
        fail: () => {
          this.$toastr("error", this.$t("common.imageIsNotAvailable"));
        },
      };
      window.$("<img>", { src }).watermark(options);
    },
    $getBrowserTime(time, format) {
      return this.$moment
        .utc(time)
        .local()
        .format(format || "MM/DD/YYYY hh:mm:ss A");
    },
    $getUTCTime(time, format) {
      return this.$moment.utc(time).format(format || "YYYY-MM-DD HH:mm:ss");
    },
    $getSlideThumbnail(
      { fileType, dziPath, thumbnailPath, path },
      defaultImage
    ) {
      const img = defaultImage || this.$icons.NO_THUMBNAIL_FOUND;
      switch (fileType) {
        case SLIDE_DIGITALSLIDE:
          return {
            src: dziPath ? thumbnailPath : img,
            alt: img,
          };
        case SLIDE_IMAGE:
          return {
            src: thumbnailPath || path || img,
            alt: path || img,
          };
        case SLIDE_RADIOLOGY:
          return {
            src: this.$icons.DICOM,
            alt: img,
          };
        case SLIDE_VIDEO:
          return {
            src: this.$icons.VIDEO_THUMBNAIL,
            alt: img,
          };
        case SLIDE_DOCUMENT:
          return {
            src: thumbnailPath || this.$icons.DOCUMENT_THUMBNAIL,
            alt: this.$icons.DOCUMENT_THUMBNAIL,
          };
        default:
          return {
            src: img,
            alt: img,
          };
      }
    },
    $getOhifURL(radiologyId, token) {
      const timestamp = new Date().getTime();
      return (
        this.$api
          .getOHIFTokenFact(
            {
              tenantId: this.$tenantData.id,
              timestamp,
              studyid: radiologyId,
            },
            token
          )
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            const { id, radiologyUrl } = this.$tenantData;
            const { VUE_APP_RADIOLOGY, VUE_APP_ENVIRONMENT, VUE_APP_PLATFORM } =
              process.env;
            const dns =
              radiologyUrl ||
              VUE_APP_RADIOLOGY ||
              "https://radiology.pathpresenter.net/";
            let output = `${dns}viewer/${radiologyId}?tenantId=${id}&timestamp=${timestamp}&token=${res.data.payload}`;
            if (VUE_APP_ENVIRONMENT) {
              output = `${output}&e=${VUE_APP_ENVIRONMENT}`;
            }
            if (VUE_APP_PLATFORM) {
              output = `${output}&p=${VUE_APP_PLATFORM}`;
            }
            return output;
          }, this.$handleError)
      );
    },
    $setWCAGVueEditorFixes() {
      window
        .$(".ql-toolbar")
        .find(".ql-formats")
        .find("button")
        .attr("aria-label", "editor");
      window
        .$(".ql-toolbar")
        .find(".ql-formats")
        .find("span")
        .attr("aria-label", "editor");
      window
        .$(".ql-container")
        .find(".ql-editor")
        .find("p")
        .find("img")
        .attr("alt", "thumbnail");
      window.$("input").attr("aria-label", "input");
      window.$(".p-dropdown-trigger").attr("aria-label", "input");
      window.$(".nav-item").find("a").attr("aria-label", "nav");
    },
    $getReCaptchaSiteKey() {
      if (process.env.VUE_APP_ENVIRONMENT === "d") {
        return "6LdeV2kpAAAAAKxlZqgUQpmCb1Vx1sdqC-6wbUK9";
      } else if (process.env.VUE_APP_ENVIRONMENT === "Staging") {
        return "6LenT2kpAAAAACJI8pRxN2sJ7GYTdzM4CokBj1Ep";
      } else if (process.env.VUE_APP_ENVIRONMENT === "Prod") {
        return "6Ld-YmgpAAAAAKrjwl_ca0_Nq9bH3llYMus98zGG";
      } else null;
    },
  },
};
